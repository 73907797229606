import type { ChatSummaryNoteCreatedNotification } from 'services/api/notifications-service/data-contracts';
import navigation from 'services/navigation';

import messages from './chat-summary-note.i18n';
import type { Mapping } from './contracts';
import { mountAvatar } from './utils';

const chatSummaryNoteCreated: Mapping<ChatSummaryNoteCreatedNotification> = (notification) => {
  const { memberNick: memberName, memberId } = notification;

  return {
    title: [messages.pages.layout.header.notifications.mappings.chatSummaryNote.created.title, { memberName }],
    avatar: mountAvatar.system(),
    handleClick: () => navigation.payingMembersNotes({ memberId: memberId.toString() }),
  };
};

export { chatSummaryNoteCreated };
