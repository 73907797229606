import { defineMessages } from 'react-intl';

export default {
  pages: {
    layout: {
      header: {
        notifications: {
          mappings: {
            chatSummaryNote: {
              created: {
                ...defineMessages({
                  title: {
                    id: 'pages.layout.header.notifications.mappings.chatSummaryNote.created.title',
                    defaultMessage: 'Note generated for {memberName}',
                  },
                }),
              },
            },
          },
        },
      },
    },
  },
};
