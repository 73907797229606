import type {
  FanClubMemberResubscribedNotification,
  FanClubMemberSubscribedNotification,
  FanClubMemberUnsubscribedNotification,
} from 'services/api/notifications-service/data-contracts';
import navigation from 'services/navigation';
import type { Loyalty } from 'components/loyalty-mask/contracts';

import messages from './fanclub.i18n';
import type { Mapping } from './contracts';
import { intlLocaleDefaultsRTF, mountAvatar, mountMessengerLink } from './utils';

const normalizePackageType = (
  type: FanClubMemberSubscribedNotification['member']['fanclubPackageType']
): 'bronze' | 'silver' | 'gold' => {
  switch (type) {
    case 'gold':
      return 'gold';
    case 'silver':
      return 'silver';
    case 'bronze':
    default:
      return 'bronze';
  }
};

const fanClubMemberSubscribed: Mapping<FanClubMemberSubscribedNotification> = (response) => {
  const {
    member: { memberNick, loyalty, fanclubPackageType },
  } = response;

  return {
    title: [
      messages.pages.layout.header.notifications.mappings.fanclub.subscribed[normalizePackageType(fanclubPackageType)],
      {
        ...intlLocaleDefaultsRTF,
        memberNick,
      },
    ],
    avatar: mountAvatar.loyalty(memberNick, {
      level: loyalty?.loyaltyName as Loyalty['level'],
      isVisible: loyalty?.isVisible,
    }),
    handleClick: () => navigation.statisticsFanClubMembers(),
  };
};

const fanClubMemberResubscribed: Mapping<FanClubMemberResubscribedNotification> = (response) => {
  const {
    member: { memberNick, loyalty },
  } = response;

  return {
    title: [
      messages.pages.layout.header.notifications.mappings.fanclub.resubscribed,
      {
        ...intlLocaleDefaultsRTF,
        memberNick,
      },
    ],
    avatar: mountAvatar.loyalty(memberNick, {
      level: loyalty?.loyaltyName as Loyalty['level'],
      isVisible: loyalty?.isVisible,
    }),
    handleClick: () => navigation.statisticsFanClubMembers(),
  };
};

const fanClubMemberUnsubscribed: Mapping<FanClubMemberUnsubscribedNotification> = (response) => {
  const {
    member: { memberNick, loyalty, memberId },
    entanglement: { level, threadId },
  } = response;

  return {
    title: [
      messages.pages.layout.header.notifications.mappings.fanclub.unsubscribed,
      {
        ...intlLocaleDefaultsRTF,
        memberNick,
      },
    ],
    avatar: mountAvatar.loyalty(memberNick, {
      level: loyalty?.loyaltyName as Loyalty['level'],
      isVisible: loyalty?.isVisible,
    }),
    handleClick: mountMessengerLink(level, memberId, threadId),
  };
};

export { fanClubMemberSubscribed, fanClubMemberResubscribed, fanClubMemberUnsubscribed };
