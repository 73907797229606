import React from 'react';
import { distinctUntilChanged, map } from 'rxjs';
import useReadonlyObservable from 'enhancers/use-readonly-observable';
import useNavigation from 'enhancers/use-navigation';
import application from 'services/application';
import authentication from 'services/authentication';
import { sendTracking } from 'services/google-analytics';
import location from 'services/router/location';
import account from 'store/account';
import user from 'store/user';
import settings from 'configurations/application/config';
import parse from 'utils/parse';

import UI, { type UserBadgeElement } from './user-badge.ui';
import tracking from './user-badge.tracking';

const UserBadge: React.FunctionComponent<unknown> = () => {
  const [isUserAuthenticated] = useReadonlyObservable(authentication.event.onChange$, authentication.isAuthenticated());
  const [email] = useReadonlyObservable(
    account.onChange$.pipe(
      map((data) => data?.email),
      distinctUntilChanged()
    ),
    account.data?.email
  );
  const [navigate, , link] = useNavigation();

  const handleLogout = React.useCallback((): void => {
    application.toggleNavigationLock(false);
    navigate.logout();
  }, [navigate]);

  const handleOnSwitchToStudioClick = React.useCallback(() => {
    application.toggleNavigationLock(false);
    user.changeToStudioView();
    sendTracking(tracking.switchToStudioView);
  }, []);

  const handleOnAddNewModelClick = React.useCallback(() => {
    sendTracking(tracking.addNewModel);

    const dashboardUrl = link.dashboard();

    location.navigate(parse.url(dashboardUrl, settings.mscPortal.hashes.openAddModel), '_self');
  }, [navigate, link]);

  const handleOnToggle: UserBadgeElement['onToggle'] = React.useCallback((open) => {
    sendTracking(tracking.toggle, { status: open ? 'open' : 'close' });
  }, []);

  if (!isUserAuthenticated) {
    return null;
  }

  return (
    <UI
      email={email}
      showSwitchToStudioButton={account.hasEnrollmentCompleted && user.isStudioAccountType() && user.isModelView()}
      showAddNewModelButton={
        account.hasEnrollmentCompleted && user.isSingleAccountType() && !user.hasMultiplePerformers()
      }
      onAddNewModelRequest={handleOnAddNewModelClick}
      onSwitchToStudioRequest={handleOnSwitchToStudioClick}
      onLogoutRequest={handleLogout}
      onToggle={handleOnToggle}
    />
  );
};

export default UserBadge;
